import * as Sentry from "@sentry/sveltekit";

const SENTRY_DSN = "https://0607d97ad0bac049338acae9fc78281e@o4507542169452544.ingest.de.sentry.io/4508348424912976";

Sentry.init({
	dsn: SENTRY_DSN,

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

// or alternatively, if you don't have a custom error handler:
export const handleError = Sentry.handleErrorWithSentry();
